import React, { useEffect, useState } from 'react'
import './Hero.css'
import { Parallax } from 'react-parallax'
import heroimg from '../assiets/img/heroimg.png'
import axios from 'axios'
import CountUp from "react-countup";

const Hero = () => {

    const [Platotal, setPlatotal] = useState("")
    const [Commtotal, setCommtotal] = useState("")


    useEffect(() => {
        gettotal()
    })

    const gettotal = () => {
        axios.get("https://unity4nationapi.doctorsforhealth.co.uk/api/v1/forms/total")
            .then((res) => {
                // console.log(res.data)
                setCommtotal(res.data.members)
                setPlatotal(res.data.commitments
                )
            }).catch((e) => {
                console.log(e)
            })

    }

    return (
        <div>
            <Parallax strength={300} bgImage={heroimg}>
                <div className='hero-main d-flex align-items-center'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='content text-lg-start'>
                                    <div className='row'>
                                        <h2>Together, Let's Build a Better Pakistan<span style={{ color: '#F74F22' }}>.</span></h2>
                                    </div>

                                    <div className='row'>
                                        <h4>Embracing unity and innovation, we join hands to forge a brighter future, shaping a better
                                            Pakistan for all. Our collective efforts and dreams are the foundation upon
                                            which we build a nation of prosperity and harmony</h4>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-lg-5 col-sm-6' >
                                            <div className='cart1'>
                                                <div className='row text-center pt-3'>
                                                    <h4>Pledges</h4>
                                                </div>
                                                <div className='row text-center'>
                                                    {/* <h3>$ {Platotal}</h3> */}
                                                    <h3>$ <CountUp
                                                        className="account-balance"
                                                        start={0}
                                                        end={Platotal}
                                                        duration={2}
                                                        useEasing={true}
                                                        separator=","
                                                    /></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-5 mt-3 mt-sm-0 mt-lg-0 col-sm-6' >
                                            <div className='cart2'>
                                                <div className='row text-center pt-3'>
                                                    <h4>Number of members</h4>
                                                </div>
                                                <div className='row text-center'>
                                                    <h3><CountUp
                                                        className="account-balance"
                                                        start={0}
                                                        end={Commtotal}
                                                        duration={2}
                                                        useEasing={true}
                                                        separator=","
                                                    /></h3>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-lg-2'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>


        </div>


    )
}

export default Hero
