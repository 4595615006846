
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Link from './compunent/ReferLink/Link';
import Form from './compunent/payment/Form';

function App() {
  return (
    <div className="App">

      <Routes>
        <Route path='/:id?' element={<Link />}></Route>
        <Route path='/form' element={<Form />}></Route>
      </Routes>
    </div>
  );
}

export default App;
