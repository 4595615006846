import React from 'react'
import './Cart2.css'
import line from '../assiets/img/line.png'
import support from '../assiets/img/support.jpg'
import box2 from '../assiets/img/box2.jpg'
import box3 from '../assiets/img/box3.jpg'
import box4 from '../assiets/img/box4.jpg'
import { useNavigate } from 'react-router-dom'


const Cart2 = () => {

  const navigate = useNavigate();

  return (
    <div className='cart2-main'>
      <div className='container my-4'>
        <div className='row pt-lg-0 mt-md-2 pt-sm-0'>
          <h4>Unity 4 Nation</h4>
        </div>
        <div className='row'>
          <h2>HOW CAN WE MAKE IT HAPPEN?</h2>
        </div>
        <div className='row  d-flex justify-content-center mt-2'>
          <img src={line} alt='' style={{ height: "7px", width: "120px" }}></img>
        </div>

        <div className='row cart2-box mt-4 mb-3'>
          <div className='col-lg-3 col-sm-6'>
            <div className='box'>
              <img src={support} className='img-fluid' alt=''></img>
              <div className='py-3 px-4'>
                <div className='row text-start'>
                  <h3>Pledge Your Support</h3>
                </div>

                <div className='row text-box text-start'>
                  <p>Your pledge is a commitment to Pakistan's future. Join us in endorsing Imran Khan's
                    leadership and be part of the force that propels our nation forward.</p>
                </div>

                <div className="row text-center mt-5 number-box">
                  <h4 className='mt-1'>1</h4>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-3 mt-3 mt-sm-0 mt-lg-0 col-sm-6'>
            <div className='box'>
              <img src={box2} className='img-fluid' alt=''></img>
              <div className='py-3 px-4'>
                <div className='row text-start'>
                  <h3>Influence Power Dynamics</h3>
                </div>

                <div className='row text-start text-box' >
                  <p>We are collectively advocating for Imran Khan's reinstatement as leader, confident in his widespread support. Our aim is to influence decisions that enable his return to power.
                  </p>
                </div>

                
                <div className="row text-center mt-4 number-box">
                  <h4 className='mt-1'>2</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-3 mt-3 mt-lg-0 col-sm-6'>
            <div className='box'>
              <img src={box3} className='img-fluid' alt=''></img>
              <div className='py-3 px-4'>
                <div className='row text-start'>
                  <h3>Breaking Free From Economic Shackles</h3>
                </div>

                <div className='row text-start text-box'>
                  <p>Pakistan's economy, burdened by IMF and international debts, compromises sovereignty.
                    United financial and moral support is key to a self-reliant and independent future.
                  </p>
                </div>
                
                <div className="row text-center mt-4 number-box">
                  <h4 className='mt-1'>3</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-3 mt-3 mt-lg-0 col-sm-6'>
            <div className='box'>
              <img src={box4} className='img-fluid' alt=''></img>
              <div className='py-3 px-4'>
                <div className='row text-start'>
                  <h3>Fueling Development Through Unity</h3>
                </div>

                <div className='row text-start text-box'>
                  <p>Unity 4 Nation's pledges have sparked major fundraising. With Imran Khan's leadership return,
                    these funds will address debts, drive development, and ensure Pakistan's prosperity.</p>
                </div>
                <div className="row text-center mt-4 number-box">
                  <h4 className='mt-1'>4</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-5 d-flex align-items-center justify-content-center">
            <button className="btn btn-primary" onClick={() => navigate("/form")}>Could you consider making a contribution to support the marketing, development, and promotion of this website to reach a wider audience?</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart2
