import React, { useState } from "react";
import "./Form.css";
import Nav from "../Nav";
import Footer from "../Footer";
import axios from "axios";
import Swal from "sweetalert2";

const Form = () => {
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [total, settotal] = useState();

    const handlesubmit = () => {
        if (name && email && total) {
            axios
                .post("https://unity4nationapi.doctorsforhealth.co.uk/api/v1/payment/pay",{name,email,total})
                .then((res) => {
                    // console.log(res);
                    window.location = res.data.forwardLink
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    };

    return (
        <div>
            <Nav />
            <div className="form-main py-5">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1>Payment</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col">
                            <div className="mb-3 text-start">
                                <label for="exampleInputEmail1" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 text-start">
                                <label for="exampleInputEmail2" className="form-label">
                                    Email address
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="exampleInputEmail2"
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 text-start">
                                <label for="exampleInputEmail3" className="form-label">
                                    Total $
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="exampleInputEmail3"
                                    value={total}
                                    onChange={(e) => settotal(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>

                    <div className="row mt-2">
                        <div className="col">
                            <button onClick={handlesubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Form;
