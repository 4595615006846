import React from "react";
import "./Cart.css";
import heroimg from "../assiets/img/banner.png";
import { Parallax } from "react-parallax";

const Cart = () => {
  return (
    <div className="cart-main">
      <div className="container-fluid mb-5">
        <div className="row pt-5">
          <h2>
            Pakistan's future shines bright with unity, progress,
            <br /> and boundless potential
          </h2>
        </div>
      </div>

      <Parallax strength={400} bgImage={heroimg}>
        <div className="box-2"></div>
      </Parallax>
    </div>
  );
};

export default Cart;
