import React from 'react'
import './Contentpage.css'
import line from '../assiets/img/line.png'
import imran from '../assiets/img/imrankhan.png'

const Contentpage = () => {
    return (
        <div className='content-main d-flex align-items-center'>
            <div className='container '>
                <div className='row my-lg-3 pb-4'>
                    <div className='col-lg-6'>
                        <img src={imran} alt='' className='img-fluid' ></img>
                    </div>
                    <div className='col-lg-6 pt-3'>
                        <div className='content-box text-lg-start'>
                            <div className='row handing-line'>
                                <h4>Welcome to Unity 4 Nation</h4>
                            </div>

                            <div className='row'>
                                <h2>Join Us in Bringing Imran Khan Back to Power for a Prosperous Pakistan</h2>
                            </div>

                            <div className='row'>
                                <div className='col-lg-2'>
                                <img src={line} alt=''></img>
                                </div>
                            </div>

                            <div className='row mt-3 pe-4'>
                                <p>At Unity 4 Nation, we are passionately committed to the rebirth of Pakistan under
                                     the dynamic leadership of Imran Khan. Recognized for his unwavering courage, 
                                     loyalty, sincerity, faith, and intelligence, Imran Khan embodies the transformative 
                                     force our nation needs. We believe in his vision for a prosperous and progressive Pakistan.
                                      This is our last chance to get our country on the right track and we can not afford to mess
                                       it up this time. Let's unite all the same-minded people on this platform and make a collective
                                     effort to build a better Pakistan under the leadership of Imran Khan.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contentpage
