import React, { useEffect, useState } from "react";
import "./Toprefer.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import person from "../../assiets/img/person.png";
import axios from "axios";

const Toprefer = () => {
  useEffect(() => {
    getdata();
  }, []);

  const [referdata, setreferdata] = useState([]);

  const getdata = () => {
    axios
      .get(
        "https://unity4nationapi.doctorsforhealth.co.uk/api/v1/users/all/refers/top"
      )
      .then((res) => {
        console.log(res.data);
        setreferdata(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="toprefer-main pt-3 pb-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Top Referees</h1>
          </div>
        </div>

        <div className="row slider-card">
          <div>
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {referdata ? (
                referdata.map((item, index) => {
                  return (
                    <SwiperSlide className="px-md-4 mb-3" key={index}>
                      <div className="card-1 g-0">
                        <div className="container">
                          <div className="row ">
                            <div className="col">
                              <img
                                src={`https://unity4nationapi.doctorsforhealth.co.uk/api/v1/users/images/${item.image}`}
                                alt=""
                                className="img-fluid mx-auto"
                              ></img>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <h2>{item.refer}</h2>
                          </div>

                          <div className="row mt-2">
                            <h3>
                              Refer<span>({item.referCount})</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <>
                  {" "}
                  <h1>helllosdd</h1>
                </>
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toprefer;
