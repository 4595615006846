import React from 'react'
import Nav from '../Nav'
import Hero from '../Hero'
import Flieldbox from '../Flieldbox'
import Contentpage from '../Contentpage'
import Cart from '../Cart'
import Cart2 from '../Cart2'
import Footer from '../Footer'
import { useParams } from "react-router-dom";
import Toprefer from './Toprefer'

const Link = () => {
  const params = useParams();
  return (
    <div>
      
      {/* <h1>hello</h1> */}
      <Nav refer={params.id}/>
      <Hero />
      <Flieldbox />
      <Toprefer/>
      <Contentpage />
      <Cart />
      <Cart2 />
      <Footer />
    </div>
  )
}

export default Link
