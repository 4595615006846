import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer-main d-flex align-items-center'>
      <div className='container my-3 my-md-0'>
        <div className='row'>
          <div className='col-lg-6 text-lg-start'>
            <div className='row'>
              <h2>© 2024 - Unity4Nation</h2>
            </div>
            <div className='row'>
              <h2>@ tahir.munj@gmail.com</h2>
            </div>
          </div>

          <div className='col-lg-6 text-lg-end'>
            <h3>Designed by <a href='https://www.cloudlabweb.com/' target='blank' style={{ color: "rgb(203, 199, 199)" }}>Cloud Lab Private Ltd</a></h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
