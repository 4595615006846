import React, { useEffect, useState } from "react";
import "./Nav.css";
import logo from "../assiets/img/unity4nation.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { allCountries } from ".././result";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Swal from "sweetalert2";
import axios from "axios";
import tahirsahab from "../assiets/img/tahir sahb.jpeg";
import { useNavigate } from "react-router-dom";

const Nav = ({ refer }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const [countryname, setcountryname] = useState([]);
  const [filtercity, setfiltercity] = useState([]);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [image, setimage] = useState(null);
  const [contact_no, setcontact_no] = useState();
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const handle = (e) => {
    e.preventDefault();
    setShow(true);
  };
  useEffect(() => {
    setcountryname(allCountries);
  }, []);
  const handlecityfilter = (e) => {
    const value = e.target.value;
    setcountry(value);
    if (value === "select country") {
    } else {
      const cites = countryname.find((ctr) => ctr.name === value).cities;
      setfiltercity(cites);
    }
  };

  const membersubmit = () => {
    if (country && city && name && contact_no && email && image) {
      const formdata = new FormData();
      formdata.append("country", country);
      formdata.append("city", city);
      formdata.append("name", name);
      formdata.append("contact_no", contact_no);
      formdata.append("email", email);
      if (refer) {
        formdata.append("refer", refer);
      }
      formdata.append("image", image);
      axios
        .post("https://unity4nationapi.doctorsforhealth.co.uk/api/v1/users", formdata)
        .then((res) => {
          if (res.data.email) {
            Swal.fire({
              title: "Thank you",
              text: "Kindly check your email and verify your account",
              icon: "success",
            });

            setTimeout(function () {
              window.location.reload();
            }, 5000);
          } else {
          }
        })
        .catch((e) => {
          if (e.response.data === "Member already exists") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Member already exists",
            });
          } else {
            console.log(e);
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "please fill input",
      });
    }
  };
  const [lgShow, setLgShow] = useState(false);

  return (
    <div>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#F74F22" }}>
              Become a member
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div
                className="container-fluid"
                style={{
                  height: "500px",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                <div>
                  <div className="row text-start acc-form">
                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          for="people"
                          className="form-label fw-bold"
                          style={{ color: "black" }}
                        >
                          Full name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="people"
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label
                          for="exampleInputEmail1"
                          className="form-label fw-bold"
                          style={{ color: "black" }}
                        >
                          Country
                        </label>
                        <select
                          className="form-select"
                          onChange={handlecityfilter}
                          aria-label="Default select example"
                        >
                          <option selected>select country</option>
                          {countryname.map((item) => {
                            return (
                              <option value={item.name} key={item._id}>
                                {" "}
                                {item.name}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label
                          for="exampleInputEmail1"
                          className="form-label fw-bold"
                          style={{ color: "black" }}
                        >
                          City
                        </label>
                        <select
                          className="form-select"
                          value={city}
                          onChange={(e) => setcity(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option selected>Select city</option>
                          {filtercity.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          for="people"
                          className="form-label fw-bold"
                          style={{ color: "black" }}
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="people"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row text-start acc-form">
                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          for="people"
                          className="form-label fw-bold"
                          style={{ color: "black" }}
                        >
                          Mobile number
                        </label>
                        {/* <input type="text" className="form-control" id="people" placeholder='' */}
                        <PhoneInput
                          className=""
                          country={"gb"}
                          value={contact_no}
                          onChange={(value) => setcontact_no(value)}
                        />
                        {/* /> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          for="people"
                          className="form-label fw-bold"
                          style={{ color: "black" }}
                        >
                          Upload image
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="people"
                          onChange={(e) => setimage(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
              Close
            </Button> */}
            <Button
              variant="primary"
              style={{ backgroundColor: "#F74F22", border: "none" }}
              className="fw-bold"
              onClick={membersubmit}
            >
              Register
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body
            style={{
              height: "550px",
              overflow: "hidden",
              overflowY: "scroll",
              padding: "10px",
            }}
          >
            <div className="container-fluid">
              <div className="row mt-2">
                <img
                  src={tahirsahab}
                  className="img-fluid mx-auto"
                  style={{
                    height: "200px",
                    width: "260px",
                    borderRadius: "50%",
                  }}
                  alt=""
                ></img>
              </div>

              <div className="row text-center mt-3">
                <h2 className="fw-bold">About us</h2>
              </div>

              <div className="row mt-4">
                <h3 style={{ fontSize: "1.5rem" }}>
                  Welcome to our community of passionate PTI activists and
                  supporters!
                </h3>
              </div>

              <div className="row mt-3">
                <p style={{ fontSize: "1.1rem", color: "gray" }}>
                  As a dedicated PTI activist, I've been unwavering in my
                  commitment to Imran Khan's vision for Pakistan. My commitment
                  reached a pinnacle when I played a pivotal role in organizing
                  one of the largest events in Scotland on May 20, 2012. Like
                  every other Pakistani, my love for our motherland runs deep,
                  and the dream of witnessing a prosperous Pakistan motivates my
                  every effort.
                </p>
              </div>

              <div className="row ">
                <p style={{ fontSize: "1.1rem", color: "gray" }}>
                  Understanding that each individual has a unique way of serving
                  their country, I envisioned creating a platform for
                  like-minded people who share a profound admiration for Imran
                  Khan. Beyond aspiring to see him lead the nation in the right
                  direction, we also recognize him as a beacon of hope for the
                  entire Muslim Ummah
                </p>
              </div>

              <div className="row ">
                <p style={{ fontSize: "1.1rem", color: "gray" }}>
                  During Imran Khan's leadership, our foreign reserves were on
                  an upward trajectory, and we were successfully paying back
                  loans. However, the abrupt change in leadership led to the
                  depletion of our reserves and an unprecedented surge in
                  inflation. Witnessing the dedication of people willing to sell
                  their valuables to support Imran Khan, I pondered the idea of
                  quantifying our collective financial sacrifice to not only
                  bring him back to power but also support him financially to
                  run the country successfully.
                </p>
              </div>

              <div className="row ">
                <p style={{ fontSize: "1.1rem", color: "gray" }}>
                  Therefore, I propose the creation of a comprehensive database,
                  a gathering of individuals who are willing to pledge varying
                  amounts to reinstate Imran Khan as our leader. By
                  transparently showcasing the financial commitment of each
                  member, we can assess our collective strength in supporting
                  Imran Khan's return to power.
                </p>
              </div>

              <div className="row ">
                <p style={{ fontSize: "1.1rem", color: "gray" }}>
                  Imagine the impact of a united front, where individuals from
                  all walks of life pledge what they can afford to ensure Imran
                  Khan's return as a great leader. This platform aims to not
                  only bring him back but to also rally behind him, offering
                  financial support to pave the way for Pakistan to become the
                  most powerful nation on the planet.
                </p>
              </div>
              <div className="row ">
                <p style={{ fontSize: "1.1rem", color: "gray" }}>
                  I invite you to join us, become a member, and to only pledge
                  an amount that resonates with your commitment to our cause.
                  Let us stand together, support our leader, and contribute to
                  the collective dream of a prosperous and powerful Pakistan.
                  Imran Khan has already paid a high cost for loving his nation;
                  now, it's our turn to stand beside him and make a difference.
                  Together, we can achieve greatness for our beloved country.
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center gap-2">
            <img src={logo} className="logo-unity" alt="" onClick={() => navigate("/")}></img>
            <h2 className="pt-2" onClick={() => navigate("/")}>Unity 4 Nation</h2>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto gap-3 my-2 mb-lg-0 button-nav">
              <form className="d-flex gap-2" role="search">
                <a
                  className="btn btn-primary"
                  href="https://panel.unity4nation.com/"
                  target="blank"
                >
                  Login
                </a>
              </form>

              <form className="d-flex gap-2" role="search">
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setLgShow(true);
                  }}
                >
                  About us
                </button>
              </form>

              <form className="d-flex gap-2" role="search">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handle}
                >
                  Become a member
                </button>
              </form>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
