import React, { useEffect, useState } from 'react'
import './Flieldbox.css'
import { allCountries } from '.././result'
import axios from 'axios';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const Flieldbox = () => {

    const [countryname, setcountryname] = useState([]);
    const [filtercity, setfiltercity] = useState([])
    const [amountdata, setamount] = useState([])
    const [frequencydata, setfrequency] = useState([])
    const [durationdata, setdurationdata] = useState([])

    const [duration, setduration] = useState("")
    const [durationfilterdata, setdurationfilterdata] = useState([])
    const [type, settype] = useState("")
    const [amount, setamountss] = useState("")
    const [frequency, setfrequencyss] = useState("")
    const [contact_no, setcontact_no] = useState()
    const [email, setemail] = useState("")
    const [full_name, setfull_name] = useState("")
    const [country, setcountry] = useState("")
    const [city, setcity] = useState("")

    useEffect(() => {
        setcountryname(allCountries)
        getdata()
    }, [])

    const getdata = () => {
        axios.get("https://unity4nationapi.doctorsforhealth.co.uk/api/v1/amounts")
            .then((res) => {
                // console.log(res.data)
                setamount(res.data)
            }).catch((e) => {
                console.log(e)
            })

        axios.get("https://unity4nationapi.doctorsforhealth.co.uk/api/v1/frequency")
            .then((res) => {
                // console.log(res.data)
                setfrequency(res.data)
            }).catch((e) => {
                console.log(e)
            })

        axios.get("https://unity4nationapi.doctorsforhealth.co.uk/api/v1/durations")
            .then((res) => {
                // console.log(res.data)
                setdurationdata(res.data)
            }).catch((e) => {
                console.log(e)
            })
    }

    const handlecityfilter = (e) => {
        const value = e.target.value;
        if (value === "select country") {
            console.warn("")
        } else {
            setcountry(value)
            const cites = countryname.find(ctr => ctr.name === value).cities
            setfiltercity(cites)

        }
    }

    const handlesave = () => {
        if (country && city && full_name && contact_no && frequency && amount && email&& duration) {
            if (frequency === "Select frequency") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "please fill input",
                });
            } else {
                console.log(frequency)
                axios.post("https://unity4nationapi.doctorsforhealth.co.uk/api/v1/forms", { country, city, full_name, email, contact_no, duration, frequency, amount })
                    .then((res) => {
                        // console.log(res.data)
                        if (res.data.amount) {
                            Swal.fire({
                                title: "Thank you",
                                text: "Successfully",
                                icon: "success"
                            });
                            setamountss("")
                            setcontact_no("")
                            setcity("")
                            setcountry("")
                            setfrequencyss("")
                            setemail("")
                            setfull_name("")
                            settype("")
                            setduration("")
                            // gettotal()
                            window.location.reload(false);
                        } else {
                            // console.log(res.data)
                        }

                    }).catch((e) => {
                        console.log(e)
                    })

            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const durationfilter = (e) => {
        const value = e.target.value;
        if (value === "One off payment") {
            setfrequencyss(value)
            setduration(1)
        } else {
            const filtered = durationdata.filter(obj => {
                return obj.frequency === value;
            });
            setfrequencyss(value)
            setdurationfilterdata(filtered)
        }
    }


    return (
        <div className='Flieldbox-main d-flex align-items-center my-5'>
            <div className='container'>
                <div className='col'>
                    <div className='box-main pt-lg-5 py-3 px-4'>
                        <div className='row text-start'>
                            <h2>What you can commit for the betterment of Pakistan?</h2>
                        </div>

                        <div className="row text-start">
                            <div className="col-lg-11">
                            <p>Imran Khan has fulfilled his obligation by making numerous sacrifices for the people of Pakistan. Now it is incumbent upon us to express our steadfast support for him in effectively leading the country. Upon his return to power, we aspire for a self-reliant Pakistan, free from the need to seek aid from the Western nations or depend on the conditional loans. Therefore, we urge you to make a pledge here indicating the financial support you will be willing to offer to the government of Pakistan contingent upon Imran Khan’s reinstatement. It's essential to clarify that this is not a payment but simply a pledge of support.</p>
                            </div>
                        </div>

                        <div className='row mt-3 fields'>
                            <div className='col-lg-4 text-start'>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Amount $</label>
                                    <input class="form-control form-control-lg"
                                        value={amount} onChange={(e) => setamountss(e.target.value)}
                                        type="number" id='exampleFormControlInput1' placeholder="" aria-label=".form-control-lg example" />
                                </div>
                            </div>

                            <div className='col-lg-4 text-start'>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Frequency</label>
                                    <select className="form-select form-select-lg mb-3"
                                        onChange={durationfilter}
                                        id="exampleFormControlInput1" aria-label=".form-select-lg example">
                                        <option selected>Select frequency</option>
                                        {
                                            frequencydata.map((item,index) => {
                                                return <option value={item.frequency} key={index}>{item.frequency}</option>
                                            })
                                        }

                                    </select>
                                </div>
                            </div>
                            {
                                frequency === "One off payment" ? "" : <>
                                    <div className='col-lg-4 text-start'>
                                        <div class="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Duration</label>
                                            <input class="form-control form-control-lg"
                                                value={duration} onChange={(e) => setduration(e.target.value)}
                                                type="number" id='exampleFormControlInput1' placeholder="" aria-label=".form-control-lg example" />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className='col-lg-4 text-start'>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Full name</label>
                                    <input class="form-control form-control-lg"
                                        value={full_name} onChange={(e) => setfull_name(e.target.value)}
                                        type="text" id='exampleFormControlInput1' placeholder="" aria-label=".form-control-lg example" />
                                </div>
                            </div>


                            <div className='col-lg-4 text-start'>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Country</label>
                                    <select className="form-select form-select-lg mb-3"
                                        onChange={handlecityfilter}
                                        id="exampleFormControlInput1" aria-label=".form-select-lg example">
                                        <option selected>select country</option>
                                        {
                                            countryname.map((item,index) => {
                                                return <option value={item.name} key={index}> {item.name} </option>
                                            })
                                        }


                                    </select>
                                </div>
                            </div>

                            <div className='col-lg-4 text-start'>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">City</label>
                                    <select className="form-select form-select-lg mb-3" id="exampleFormControlInput1"
                                        value={city} onChange={(e) => setcity(e.target.value)}
                                        aria-label=".form-select-lg example">
                                        <option selected>select city</option>
                                        {
                                            filtercity.map((item,index) => {
                                                return <option value={item} key={index}>{item}</option>
                                            })
                                        }


                                    </select>
                                </div>
                            </div>

                            <div className='col-lg-4 text-start'>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Email</label>
                                    <input class="form-control form-control-lg" type="email"
                                        value={email} onChange={(e) => setemail(e.target.value)}
                                        id='exampleFormControlInput1' placeholder="" aria-label=".form-control-lg example" />
                                </div>
                            </div>

                            <div className='col-lg-4 text-start'>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Mobile number</label>
                                    <PhoneInput
                                        className=""
                                        country={'gb'}
                                        value={contact_no}
                                        onChange={(value) => setcontact_no(value)}
                                    />
                                </div>
                            </div>

                            <div className='col-lg-2 d-flex align-items-center justify-content-start mt-2'>
                                <button className='btn btn-primary mt-1'>Total <span className="ms-2">$ {frequency === "One off payment" ? amount * 1 : amount && duration ? amount * duration : 0}</span></button>
                            </div>

                            <div className='col-lg-2 d-flex align-items-center justify-content-start mt-2'>
                                <button className='btn btn-primary mt-1' onClick={handlesave}>Submit</button>
                            </div>


                        </div>




                    </div>
                </div>
            </div>
        </div>
    )
}

export default Flieldbox
